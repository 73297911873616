/** @format */



const routes = [
  // manage user
//   {
//     path: "/dashboard",
//     exact: true,
//     component: <DashBoard />,
//     main: () => <DashBoard />,
//   },
];

export default routes;
